<div class="row-detail">
  <div class="col-image">
    <picture>
      <source type="image/webp" srcset="/assets/images/landing-page/iTAXapp-preview01.webp">
      <source type="image/jpeg" srcset="/assets/images/landing-page/iTAXapp-preview01.jpg">
      <img srcset="/assets/images/landing-page/iTAXapp-preview01.jpg" alt="iTAX App" title="iTAX App" width="200" height="340">
    </picture>
  </div>
  <div class="col-detail">

    <div *ngIf="type=='calculate'">
      <h2 class="h2 mb-8px">มีรายได้ทางอื่นด้วย?</h2>
      <p class="text-gray-medium mb-24px">ไม่ว่าคุณจะเป็นมนุษย์เงินเดือน ฟรีแลนซ์  ขายของออนไลน์ นักลงทุน เล่นหุ้น เทรดคริปโต แอป iTAX จะช่วยคำนวณภาษีเงินได้บุคคลธรรมดา และวางแผนภาษีอย่างละเอียด แม่นระดับเศษสตางค์ มาตรฐานเดียวกันกับกรมสรรพากร พร้อมอัพเดตสิทธิประโยชน์ทางภาษีล่าสุด</p>
    </div>

    <div *ngIf="type=='result'">
      <h2 class="h2 mb-16px">วางแผนภาษีด้วยแอป iTAX</h2>
      <ul class="mb-24px">
        <li *ngFor="let item of appServices">
          <i class="fas fa-check-circle text-primary"></i>{{item}}
        </li>
      </ul>
    </div>

    <div class="app-info mb-24px">
      <div class="app-icon">
        <img src="../../../../assets/images/appicon.svg" alt="iTAX app icon" title="iTAX app icon" width="70" height="70">
      </div>
      <div>
        <h4 class="text-center text-md-left h4 mb-8px">iTAX แอปคำนวณ/วางแผนภาษี</h4>
        <div class="icon-store">
          <a href="https://www.itax.me/download">
            <itax-icon [name]="'appstore'" [width]="'120px'" [height]="'38px'"></itax-icon>
          </a>
          <a href="https://www.itax.me/download">
            <itax-icon [name]="'playstore'" [width]="'120px'" [height]="'38px'"></itax-icon>
          </a>
        </div>
      </div>
    </div>

    <div class="text-center text-md-left">
      <a class="btn btn-primary" href="https://www.itax.me/download">Download ฟรี</a>
    </div>
  </div>
</div>